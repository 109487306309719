/** @jsx jsx */

import MySEO from '../components/seo'
import {Container,Box,jsx,Flex,Heading,Text} from 'theme-ui'

import Layout from '../components/layout'

export default function Datenschutz(){
  return(
   <Layout>
     <Flex>
<Container sx={{p:1,m:0,flexDirection:`column`}}>
<MySEO title="Datenschutz" description="Die Datenschutzbestimmungen des Vereins" />
<Box sx={{ width:`98%`}}>

<Heading sx={{fontWeight:500,color:'text'}} as="h1">Datenschutz</Heading>
<Text>
E-Mail: <span>kontakt@kulturnetz-oberes-mittelrheintal.org </span> <br/>
Datenschutzbeauftragter: René Broich

55422 Bacharach // Langstr. 48
</Text>




<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Hosting und E-Mail-Versand</Heading>

<Text>
Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.
</Text>

<Text>
Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).

</Text>


<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Google Tag Manager</Heading>
<Text>
Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine Oberfläche verwalten können
 (und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser Onlineangebot einbinden). 
 Der Tag Manager selbst (welches die Tags implementiert) verarbeitet keine personenbezogenen Daten der Nutzer. 
 Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu den 
 Google-Diensten verwiesen.  <a href="https://www.google.com/intl/de/tagmanager/use-policy.html"> Nutzungsrichtlinien </a>.
</Text>




<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Google Analytics</Heading>


<Text>Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
</Text>
<Text>
Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten: <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">Googles Garantie</a>.
</Text>
<Text>Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.</Text>

<Text>Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.</Text>

´

<Text>Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/privacy">Googles Datenschutz</a>) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google (<a href="https://adssettings.google.com/authenticated">Google Werbeeinblendungen)</a>).</Text>

<Text>
Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin 
herunterladen und installieren:  <a href="http://tools.google.com/dlpage/gaoptout?hl=de">Downloaden und Installieren</a>.
</Text>
<Text>
Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.
</Text>




<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Onlinepräsenzen in sozialen Medien</Heading>
<Text>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, 
Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen informieren zu können.</Text>

<Text>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet werden können. 
Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der 
Rechte der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter die unter dem Privacy-Shield zertifiziert sind, 
weisen wir darauf hin, dass sie sich damit verpflichten, die Datenschutzstandards der EU einzuhalten.</Text>

<Text>
Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und Werbezwecke verarbeitet.
So können z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden.
Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Plattformen zu schalten,
die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der 
Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den 
Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
</Text>

<Text>
Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen an 
einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. 
Falls die Nutzer von den jeweiligen Anbietern der Plattformen um eine Einwilligung in die vorbeschriebene Datenverarbeitung
 gebeten werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.

Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out),
 verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.
</Text>

 <Text>
 Auch im Fall von Auskunftsanfragen und der Geltendmachung von Nutzerrechten, weisen wir darauf hin, 
dass diese am effektivsten bei den Anbietern geltend gemacht werden können. 
Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen 
ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
 </Text>

<Text>
- Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) 
auf Grundlage einer Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten:<br/>
<a href="https://www.facebook.com/about/privacy/">Datenschutzerklärung</a>,  
<a href="https://www.facebook.com/legal/terms/information_about_page_insights_data">speziell für Seiten</a> , 
 <a href="https://www.facebook.com/settings?tab=ads und http://www.youronlinechoices.com">Opt-Out</a>, 
 <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">Privacy Shield</a>.
<br/>
- Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) <br/>
 <a href="https://policies.google.com/privacy">Datenschutzerklärung</a>, 
 <a href="https://adssettings.google.com/authenticated">Opt-Out</a>, 
 
 <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">Privacy Shield </a>.
<br/>
- Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) –  <br/> 
<a href="http://instagram.com/about/legal/privacy/">Datenschutzerklärung/ Opt-Out</a>.

- Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) - 
 <a href="https://twitter.com/de/privacy">Datenschutzerklärung</a>, 
<a href="https://twitter.com/personalization"> Opt-Out </a>, 
<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active">Privacy Shield </a>.
<br/> 
<br/> 
<strong>Erstellt mit Datenschutz-Generator.de von RA Dr. jur. Thomas Schwenke</strong>
</Text>

<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Einbindung von Diensten und Inhalten Dritter</Heading>
<Text>Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse,
 Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) 
 Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos oder 
 Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).</Text>

 <Text>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, 
da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. 
Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. 
Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die 
IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter 
können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) 
für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, 
wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. 
Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden
 und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, 
 Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, 
 als auch mit solchen Informationen aus anderen Quellen verbunden werden.
</Text>



<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Vimeo</Heading>
<Text>
Wir können die Videos der Plattform “Vimeo” des Anbieters Vimeo Inc., Attention: Legal Department, 
555 West 18th Street New York, New York 10011, USA, einbinden.<br/>
<a href="https://vimeo.com/privacy">Datenschutzerklärung </a>. 
Wir weisen darauf hin, dass Vimeo Google Analytics einsetzen kann und verweisen hierzu auf die Datenschutzerklärung <br/>
( <a href="https://policies.google.com/privacy">Datenschutzerklärung</a>) 
s ( <a href="http://tools.google.com/dlpage/gaoptout?hl=de">owie Opt-Out-Möglichkeiten für Google-Analytics</a>) 
oder die Einstellungen von Google für die Datennutzung zu Marketingzwecken 
( <a href="https://adssettings.google.com/">Einstellungen von Google für die Datennutzung zu Marketingzwecken</a>)
</Text>




<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Youtube</Heading>
<Text>Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. <br/>
<a href="https://www.google.com/policies/privacy/">Datenschutzerklärung</a>, 
<a href="https://adssettings.google.com/authenticated">Opt-Out </a>
<br/></Text>


<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Google Fonts</Heading>
<Text>Wir binden die Schriftarten ("Google Fonts") des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.<br/>
<a href="https://www.google.com/policies/privacy/"> Datenschutzerklärung </a>, 
<a href="https://adssettings.google.com/authenticated">Opt-Out </a>
<br/>
</Text>


<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Google Maps</Heading>
<Text>Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google LLC,
 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Zu den verarbeiteten Daten 
 können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne 
 deren Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), 
 erhoben werden. Die Daten können in den USA verarbeitet werden.</Text>
 
 <a href="https://www.google.com/policies/privacy/">Datenschutzerklärung </a> 
 <a href="https://adssettings.google.com/authenticated">Opt-Out </a>




<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Verwendung von Facebook Social Plugins</Heading>
<Text>Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, 
Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) 
Social Plugins ("Plugins") des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 
Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook").
Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer 
Inhalte dieses Onlineangebotes innerhalb von Facebook teilen können. Die Liste und das Aussehen der 
Facebook Social Plugins kann hier eingesehen werden: <br/>
<a href="https://developers.facebook.com/docs/plugins/">Liste ansehen</a>
<br/>

Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, 
das europäische Datenschutzrecht einzuhalten <br/>
<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">Abkommmen s.o.</a></Text>

<Text>Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enthält, 
baut sein Gerät eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von 
Facebook direkt an das Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. 
Dabei können aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher 
keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert 
die Nutzer daher entsprechend unserem Kenntnisstand.</Text>

<Text>Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite 
des Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
 Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button betätigen
  oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook übermittelt
   und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit, 
   dass Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur 
   eine anonymisierte IP-Adresse gespeichert.</Text>

   <Text>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook
 sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, 
 können diese den Datenschutzhinweisen von Facebook entnehmen: 
 <a href="https://www.facebook.com/about/privacy/">Opt-Out </a></Text>


 <Text>Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Onlineangebot Daten 
über ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der 
Nutzung unseres Onlineangebotes bei Facebook ausloggen und seine Cookies löschen. Weitere Einstellungen und
 Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen möglich:<br/>
 <a href="https://www.facebook.com/settings?tab=ads"> Widersprüche zur Nutzung von Daten für Werbezwecke</a>
  <a href="http://www.aboutads.info/choices/">oder </a>  
  oder die EU-Seite 
  <a href="http://www.youronlinechoices.com/"> über die US-amerikanische Seite</a> 
  Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, 
  wie Desktopcomputer oder mobile Geräte übernommen.</Text>
  
  <Text>Wir sind bemüht, unser Webangebot stets aktuell und inhaltlich richtig sowie vollständig anzubieten. 
Dennoch ist das Auftreten von Fehlern nicht völlig auszuschließen. Das Kultunetz übernimmt keine Haftung für die Aktualität, die inhaltliche Richtigkeit sowie für die Vollständigkeit der in seinem Webangebot eingestellten Informationen, es sei denn die Fehler wurden vorsätzlich oder grob fahrlässig aufgenommen. Dies bezieht sich auf eventuelle Schäden materieller oder ideeller Art Dritter, die durch die Nutzung dieses Webangebotes verursacht wurden.</Text>

<Text>Sofern innerhalb des Internetangebotes die Möglichkeit der Eingabe von persönlichen Daten (E-Mail-Adresse, Namen, Anschriften) besteht, erfolgt diese freiwillig. Das Kulturnetz erklärt ausdrücklich, dass es diese Daten nicht an Dritte weitergibt.</Text>

<Text sx={{fontWeight:500,py:2}}>Alle oben gelisteten Datenschutzerklärungen wurden erstellt mit dem Datenschutz-Generator.de von RA Dr. jur. Thomas Schwenke </Text>


<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Rechtswirksamkeit</Heading>
<Text>Dieser Haftungsausschluss ist Teil unseres Internetangebotes, Bacharach. 
Sofern einzelne Formulierungen oder Teile dieses Textes der geltenden Rechtslage nicht
 mehr oder nicht mehr vollständig entsprechen, bleiben die übrigen Teile dieser Erklärung davon unberührt.</Text>


<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Copyright</Heading>
<Text>Das Layout der Homepage, die verwendeten Grafiken und Bilder sowie die einzelnen Beiträge sind urheberrechtlich geschützt.
</Text>

<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Externe Verweise und Links</Heading>
<Text>Wir haben keinen Einfluss auf Gestaltung und Inhalte fremder Internetseiten. 
Wir distanzieren uns daher von allen fremden Inhalten, auch wenn ein Link auf unsere Seite führt.   
 
Diese Erklärung gilt für alle auf unserer Website angezeigten
 Links und für alle Inhalte der Seiten, zu denen die bei uns angemeldeten Banner und Links führen.
</Text>

<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Fotos</Heading>
<Text>Die auf der Website von uns verwendeten Fotos unterliegen dem Copyright des jeweiligen Fotografen/Künstlers. 
Wir bemühen uns immer um korrekte Quellenangaben.</Text>


<Heading sx={{color:'text',pt:30,pb:0,fontWeight:500}}> Video-Trailer</Heading>
<Text>Die auf der Website von uns verwendeten Videos/Trailer unterliegen dem Copyright des jeweiligen Künstlers, 
auch hier bemühen wir uns immer um korrekte Quellenangabe.

Für Links, die auf andere Seiten verweisen, übernehmen wir keine Verantwortung, 
ebenfalls für Verlinkungen auf unsere Webseite.</Text>


</Box>
</Container>
</Flex>
   </Layout>
  )
}
